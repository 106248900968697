/***************************************************************************************************
 * COMPONENTS AND THEMES
 */

/***************************************************************************************************
  * DECLARATIONS
  */
@use 'node_modules/ag-grid-community/styles' as ag;

/*
 * Angular Material, material design components
 * see https://material.angular.io

 */

@import 'libs/shared/ui/styles/src/lib/scss/material-theme';

/*
   * ag grid components
   * see https://www.ag-grid.com/angular-grid
   */
@include ag.grid-styles(
  (
    theme: material,
    material-primary-color: $primary,
    material-accent-color: $info,
    checkbox-checked-color: $primary,
  )
);
/***************************************************************************************************
   * UTILITIES
   */

/*
   * TailwindCSS, utility-first css framework
   * see https://tailwindcss.com/docs
   */
@tailwind base;
@tailwind components;
@tailwind utilities;

gq-root {
  font-family:
    Noto Sans,
    sans-serif !important;
}
// schaeffler-Tag overrides
schaeffler-tag {
  .tag-success {
    @apply text-quotation-status-green #{!important};
  }
}

schaeffler-tag.subheader-status-icon {
  .tag-info,
  .tag-success,
  .tag-warning,
  .tag-neutral,
  .tag-error {
    @apply p-1 min-h-[unset] min-w-[unset] #{!important};
  }
  .tag-dot {
    @apply p-1 h-3 w-3 mr-[unset] #{!important};
  }

  .tag-success {
    @apply bg-quotation-status-dark-green bg-opacity-20 #{!important};
    .tag-dot {
      @apply bg-quotation-status-dark-green #{!important};
    }
  }
}

.ag-theme-material {
  --ag-font-family: 'Noto sans';
  --ag-selected-row-background-color: #eee;
  .ag-column-panel {
    border-left: 1px solid ag-param(border-color);
    border-right: 1px solid ag-param(border-color);
  }
  .ag-header-cell-text {
    font-size: 14px;
  }
}

.mat-mdc-table {
  .mat-mdc-header-cell {
    font-family: Noto Sans !important;
  }
  .mdc-data-table__cell {
    font-family: Noto Sans !important;
  }
}

.mat-datepicker-content .mat-datepicker-close-button {
  @apply hidden;
}

.mat-mdc-dialog-surface {
  padding: 16px !important;
}

.mat-mdc-tab-group {
  .mat-ripple-element {
    display: none;
  }
}

button {
  outline: none !important;
}

.align-arrow-forward {
  vertical-align: -4px !important;
}

.global-search-modal .mat-mdc-dialog-surface {
  @apply px-0 #{!important};
}

.global-search-advanced-modal:has(.cases-results-table-with-pagination) {
  @apply top-8 bottom-16 h-[unset] fixed #{!important};
}

.global-search-advanced-modal:has(.material-results-table-with-pagination) {
  @apply top-8 bottom-16 h-[unset] fixed #{!important};
}
.global-search-advanced-modal {
  @apply h-[630px] top-8 fixed #{!important};
  // enter classes here
  .mat-mdc-tab-group {
    @apply h-full;
  }
  .mat-mdc-tab-body-wrapper {
    @apply h-full;
  }

  mat-slide-toggle {
    .mdc-label {
      @apply text-medium-emphasis #{!important};
      @apply cursor-pointer;
    }
  }

  .mat-mdc-radio-button {
    &.mat-mdc-radio-checked .mdc-label {
      @apply text-high-emphasis #{!important};
      @apply cursor-pointer;
    }

    .mdc-label {
      @apply text-medium-emphasis #{!important};
      @apply cursor-pointer;
    }

    .mdc-radio {
      --mdc-radio-state-layer-size: 28px;
    }
  }
}

.release-modal .mat-mdc-dialog-surface {
  padding: 0 !important;
}

.editing-modal mat-form-field {
  line-height: normal;
}

.create-manual-case-modal,
.add-material-dialog {
  .mat-mdc-button-base .mdc-button__label,
  .mat-mdc-button-base .mat-icon {
    z-index: 0;
  }
}

gq-create-manual-case-view:has(.newCaseCreateView) {
  .ag-root-wrapper-body {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px;
  }
}
.edit-material-modal {
  .mat-mdc-button-base .mdc-button__label {
    z-index: 0;
  }
}
.edit-case-modal {
  .mat-mdc-button-base .mdc-button__label {
    z-index: 0;
  }
  .mat-mdc-dialog-surface {
    @apply p-0 #{!important};
  }
  mat-dialog-content {
    @apply p-4 #{!important};
  }

  mat-dialog-content.mat-mdc-dialog-content {
    max-height: 90vh !important;
    color: unset;
    font-family: unset;
  }
}

.add-material-dialog {
  gq-material-input-table > div {
    @apply h-full;
  }
}
.mdc-tooltip__surface {
  @apply py-2 #{!important};
  @apply px-4 #{!important};
  @apply text-left #{!important};
  @apply bg-secondary-900 #{!important};
  @apply text-caption #{!important};
}

gq-kpi-status-card {
  .mat-mdc-button-base .mdc-button__label {
    z-index: 0;
  }
}
.pricing-assistant-modal {
  @apply inset-y-8 fixed #{!important};

  .mat-mdc-dialog-surface {
    @apply p-0 #{!important};
  }
  mat-dialog-content {
    @apply p-4 #{!important};
  }
  mat-dialog-content.mat-mdc-dialog-content {
    max-height: 95vh !important;
    font-family:
      Noto Sans,
      sans-serif !important;
  }
  .mat-mdc-button-base .mdc-button__label {
    z-index: 0;
  }
  .mat-mdc-dialog-container {
    @apply flex;
  }

  .mdc-dialog__container {
    @apply w-full;
  }

  .mat-mdc-tab {
    @apply px-0;
    min-width: 60px;

    &.mat-mdc-tab-disabled {
      @apply opacity-100;
      @apply pointer-events-none;
      @apply cursor-default;
    }

    .mdc-tab-indicator__content--underline {
      @apply rounded-t-3xl;
      @apply border-t-4;
    }
  }
  .mat-mdc-tab-label-container {
    z-index: 0;
  }
  .mat-mdc-tab-labels {
    @apply justify-between;
    @apply flex;
    @apply px-4;
  }

  .mat-mdc-tab-label {
    @apply p-0;
    @apply pb-3;
    min-width: 60px;

    span {
      @apply text-medium-emphasis;
    }
  }

  .mat-mdc-tab-label-content {
    @apply h-full;
  }

  .mat-mdc-tab-label-active {
    span {
      @apply text-link;
    }
  }

  .mat-mdc-tab-body-wrapper {
    @apply h-full;
    @apply bg-secondary-900 bg-opacity-5;
    @apply z-0;
  }

  // radio Button/ Group
  .mdc-radio {
    @apply scale-[0.7];
  }
}

.show-more {
  @apply z-50;
}
.mat-mdc-snack-bar-container {
  .mdc-snackbar__label {
    @apply text-body-2 #{!important};
  }
  .mdc-snackbar__surface {
    @apply bg-high-emphasis #{!important};
    @apply text-white-high-emphasis #{!important};
  }
}
.mdc-tab__content {
  @apply text-button;
  @apply leading-4;
}
.mat-mdc-menu-item {
  @apply text-body-2 #{!important};
}

.mdc-button__label {
  @apply whitespace-nowrap font-medium text-body-2 uppercase;
}

// This fixes an ui issue when the form field is opened with appearance outline
// Note: class name is repeated to achieve sufficient specificity over the various MDC states.
// (hover, focus, etc.
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field {
  &.mat-mdc-form-field .mdc-notched-outline__notch {
    border-right: 1px solid transparent;
  }
}

.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline
  .mdc-notched-outline__notch {
  @apply max-w-full #{!important};
}

.mat-mdc-form-field-icon-suffix > .mat-icon {
  @apply p-0 #{!important};
  @apply relative #{!important};
  @apply text-medium-emphasis #{!important};
}

mat-error,
mat-hint {
  @apply text-caption;
  @apply text-medium-emphasis;
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  @apply py-0 #{!important};
}

.ag-row-group-indent-1 {
  @apply ml-4 #{!important};
  @apply pl-4 #{!important};
}
.ag-group-contracted,
.ag-group-expanded {
  @apply mr-2 #{!important};
}

.delta-chip {
  @apply flex items-center justify-center w-full overflow-hidden rounded-full px-3 py-1 uppercase;

  span {
    @apply text-[10px] font-bold whitespace-nowrap;
  }
}

.mat-expansion-panel-header {
  @apply h-auto #{!important};
  @apply py-3 #{!important};
}

@layer base {
  body {
    // dragging of column headers in AG Grid table is only possible within body range
    @apply min-h-screen;
  }
}
gq-quotation-details-table {
  .mat-mdc-button-base {
    z-index: 0;
  }
  gq-extended-column-header {
    .ag-header-cell-label {
      z-index: 0;
    }
    overflow: hidden !important;
  }
}
// checks whether gq Table horizontal scrollbar is visible, and if so it will apply the setting
gq-case-table:has(.ag-body-horizontal-scroll:not([style*='height: 0px;'])),
gq-quotation-details-table:has(
    .ag-body-horizontal-scroll-viewport:not([style*='height: 0px;'])
  ) {
  .ag-status-bar {
    .delete-button-open-cases-tab {
      @apply bottom-24 #{!important};
    }
    .delete-button-quotation-details {
      @apply bottom-28 #{!important};
    }
  }
}

// checks whether gq Table vertical scrollbar is visible, and if so it will apply the setting
gq-case-table:has(
    .ag-body-vertical-scroll-viewport:not([style*='width: 0px;'])
  ),
gq-quotation-details-table:has(
    .ag-body-vertical-scroll-viewport:not([style*='width: 0px;'])
  ) {
  .ag-status-bar {
    .delete-button-open-cases-tab {
      @apply right-12 #{!important};
    }

    .delete-button-quotation-details.panel-opened {
      @apply right-64 #{!important};
    }
    .delete-button-quotation-details.panel-closed {
      @apply right-20 #{!important};
    }
  }
}

gq-quotation-details-table {
  .ag-status-bar {
    .ag-status-bar-left {
      @apply items-center #{!important};
    }
    .ag-status-bar-center {
      @apply items-center #{!important};
    }
    .ag-status-bar-right {
      @apply items-center #{!important};
    }
  }
}
